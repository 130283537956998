<template lang="pug">
Loading
DynamicIndex
//- .main
  .bg.bg--sky
    .skyblock
      video(playsinline autoplay muted loop)
        source(src='/static/sky2Clip.mov' type="video/mp4")
  img.bg.bg--mental(src="@/assets/images/index-top.png")
  img.bg.bg--line.d-none.d-lg-block(src="@/assets/images/bg-rightbottom.png")
  img.bg.bg--linemd.d-block.d-lg-none(src="@/assets/images/index-bgmoblie.png")
  img.bg.bg--logo(src="@/assets/images/index-logo.svg")
  //- .lang
    img.lang__img(:src="require(`@/assets/images/navbar-global.svg`)")
    .lang__item(@click="toPage('index')") 繁中
    .lang__item(@click="toPage('index')") En
  .circle__outblock
    .circle.circle--artistroom(@click="toPage('artists-room')")
      .circle__block.circle__block--artistroom.flex-center
        .circle__block__text {{ $t('shared.artist') }}
    .circle.circle--fmaf(@click="toPage('fmaf')")
      .circle__block.circle__block--fmaf.flex-center
        .circle__block__text {{ $t('shared.artwork') }}
    .circle.circle--fvl(@click="toPage('fvl')")
      .circle__block.circle__block--fvl.flex-center
        .circle__block__text FUTURE
        .circle__block__text VISION LAB
    .circle.circle--about(@click="toPage('about')")
      .circle__block.circle__block--about.flex-center
        .circle__block__text {{ $t('shared.about') }}
    .circle.circle--activity(@click="toPage('activity')")
      .circle__block.circle__block--activity.flex-center
        .circle__block__text {{ $t('shared.event') }}
//- HomeCarousel
</template>

<script>
import Loading from "@/component/Loading";
import DynamicIndex from "@/component/DynamicIndex";
import HomeCarousel from "@/component/HomeCarousel";

export default {
  name: "index",
  components: { Loading,DynamicIndex, HomeCarousel },
  methods: {
    toPage(index) {
      this.$router.push({ path: index });
    },
  },
};
</script>

<style lang="sass" scoped>
.main
  position: relative
  min-height: 100vh
  overflow: hidden

.bg
  position: absolute
  z-index: -1

  &--mental
    top: -25px
    left: 0
    width: 36%
    +rwd_md
      width: 59%
    +rwd_sm
      top: -4px
      left: 0
      width: 75%

  &--sky
    width: 100%
    height: 100%
    top: 91px
    right: 120px
    +rwd_md
      width: 85%
      right: 17%
      top: 12%
    +rwd_sm
      width: 85%
      right: 17%
      top: 12%

  &--logo
    display: block
    left: 15%
    top: 50%
    transform: translateY(-43%)
    +rwd_md
      position: static
      width: 63%
      padding-top: 13vh
      margin-left: auto
      margin-right: auto
      transform: none
    +rwd_sm
      width: 59%
      padding-top: 18vh
      margin-left: auto
      margin-right: auto

  &--line
    width: 65%
    right: 0
    bottom: 0

  &--linemd
    width: 100%
    height: auto
    bottom: 0
    +rwd_md
      width: auto
      height: 100%
      bottom: 0
      right: 0
    +rwd_sm
      width: 100%
      height: auto
      bottom: 0
      right: 0


.skyblock
  position: relative
  // padding-top: 67%
  width: 100%
  height: 100%
  overflow: hidden

  video
    width: 100%
    height: 100%
    position: absolute
    // top: 50%
    // left: 50%
    // transform: translate(-50%, -43%)
    object-fit: cover
    display: block
    margin: 0
    padding: 0

.lang
  z-index: 1200
  cursor: pointer
  display: flex
  justify-content: flex-end
  align-items: center
  padding-top: 22px
  padding-right: 20px
  position: relative
  +rwd-md
    display: none

  &__img
    margin-right: 4px
    margin-bottom: 1px

  &__item
    line-height: 12px
    margin-right: 4px

    &:hover
      background-color: #000000
      color: white

.circle__outblock
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  +rwd-md
    position: relative
    width: 100%
    height: 38vh
    margin-top: 10vh

.circle
  cursor: pointer
  position: absolute
  transition: 1.2s

  &:hover
    transform: scale(1.2)
    transition: 0.7s

  &--artistroom
    bottom: 20%
    right: 38%
    +rwd_md
      bottom: 20vh
      right: 74%

  &--fmaf
    bottom: 37%
    right: 30%
    +rwd_md
      bottom: 29vh
      right: 59%

  &--fvl
    bottom: 13%
    right: 20%
    +rwd_md
      bottom: 14vh
      right: 40%

  &--about
    bottom: 25%
    right: 10%
    +rwd_md
      bottom: 21vh
      right: 20%

  &--activity
    bottom: 36%
    right: 3%
    +rwd_md
      bottom: 28vh
      right: 2%

  &__block
    position: relative
    align-self: center
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%)
    width: 191px
    height: 191px
    border-radius: 50%
    flex-direction: column
    +rwd_sm
      width: 143px
      height: 143px

    &--artistroom
      width: 100vw
      height: 100vw
      max-width: 148px
      max-height: 148px
      +rwd_sm
        width: 21vw
        height: 21vw

    &--fmaf
      width: 100vw
      height: 100vw
      max-width: 92px
      max-height: 92px
      +rwd_sm
        width: 20vw
        height: 20vw

    &--fvl
      width: 100vw
      height: 100vw
      max-width: 142px
      max-height: 142px
      +rwd_sm
        width: 28vw
        height: 28vw

    &--about
      width: 100vw
      height: 100vw
      max-width: 121px
      max-height: 121px
      +rwd_sm
        width: 20vw
        height: 20vw

    &--activity
      width: 100vw
      height: 100vw
      max-width: 87px
      max-height: 87px
      +rwd_sm
        width: 19vw
        height: 19vw

    &__text
      position: static
      font-size: 18px
      line-height: 29px
      color: #ffffff
      +rwd-md
        font-size: 14px
</style>
