<template lang="pug">
.modal.flex-center
  .btn__close-modal(@click="$emit('closeModal')") X
  YouTube.video-youtube(
    :src="videoUrl",
    width="100%",
    height="100%",
    :vars="{ autoplay: 1, controls: 1 }"
  )
</template>

<script>
import YouTube from "vue3-youtube";
import { onMounted, onUnmounted, ref } from "vue";
import { emitter } from "@/utils/utils";

export default {
  name: "Loading",
  components: {
    YouTube
  },
  props: {
    videoUrl: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const show = ref(true);

    return {
      show,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 1000;
  .btn__close-modal {
    font-family: sans-serif;
    position: absolute;
    font-size: 2.5rem;
    color: white;
    position: absolute;
    right: 2rem;
    top: 3.5rem;
    z-index: 1;
    cursor: pointer;
  }
  iframe, .video-youtube{
    width: 100%;
    height: 100%;
  }
}
</style>
