<template>
  <div id="dynamic-index">
    <img class="mainTitle" src="./mobileTitle.svg" alt="title" />
    <div class="indicator">
      <img class="mouse" src="indicator/Rectangle 59.png" alt />
      <!-- <div class="flx">
        <div class="left">
          <img src="indicator/scroll.png" alt />
        </div>
        <img class="middleline" src="indicator/Vector 172.png" alt />
        <div class="right">
          <img src="indicator/導覽模式.png" alt />
        </div>
      </div>-->
    </div>
    <div class="videoWrap">
      <video src="/static/sky2Clip.mov" autoplay muted loop></video>
    </div>

    <div class="circle__outblock">
      <div class="circle circle--artistroom">
        <a
          href="/artists-room"
          target="_self"
          class="circle__block circle__block--artistroom flex-center"
        >
          <div class="circle__block__text">{{ $t('shared.artist') }}</div>
        </a>
      </div>
      <div class="circle circle--fmaf">
        <a href="/fmaf" target="_self" class="circle__block circle__block--fmaf flex-center">
          <div class="circle__block__text">{{ $t('shared.artwork') }}</div>
        </a>
      </div>
      <div class="circle circle--fvl">
        <a href="/fvl" target="_self" class="circle__block circle__block--fvl flex-center">
          <div class="circle__block__text">FUTURE</div>
          <div class="circle__block__text">VISION LAB</div>
        </a>
      </div>
      <div class="circle circle--about">
        <a href="/about" target="_self" class="circle__block circle__block--about flex-center">
          <div class="circle__block__text">{{ $t('shared.about') }}</div>
        </a>
      </div>
      <div class="circle circle--activity">
        <a
          href="/activity"
          target="_self"
          class="circle__block circle__block--activity flex-center"
        >
          <div class="circle__block__text">{{ $t('shared.event') }}</div>
        </a>
      </div>
    </div>
    
    <div class="navbar__lang">
      <img class="navbar__lang__img" src="@/assets/images/navbar-global.svg" />
      <span 
        class="navbar__lang__item"
        :class="{'is__active': $i18n.locale === 'zh'}"
        @click="changeLocale('zh')"
      >
        繁中
      </span>
      <span 
        class="navbar__lang__item"
        :class="{'is__active': $i18n.locale === 'en'}"
        @click="changeLocale('en')"
      >
        En
      </span>
    </div>
    <div class="scripts" ref="scripts"></div>
  </div>
</template>

<script>
export default {
  name: "DynamicIndex",
  mounted() {
    // 直接把 mySketch.js 塞到 p5.js 的最下面，在一支 js 裡面執行，確保執行順序沒有問題
    this.appendScript("static/scripts/p5.js");

    // this.appendScript("static/scripts/mySketch.js", true);
  },
  methods: {
    appendScript(fileName, isDefer) {
      const scriptEl = this.$refs.scripts;
      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", fileName);
      if (isDefer) script.defer = true;
      scriptEl.appendChild(script);
    },
    changeLocale(locale) {
      localStorage.setItem("lang", locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss" scoped>
#dynamic-index {
  bottom: 0;
  padding: 0;
}

.mainTitle {
  display: none;
  position: absolute;
  z-index: 100;
  height: auto;
  left: 15%;
  top: 50%;
  transform: translateY(-43%);
}
.isMobile .mainTitle {
  display: block;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .mainTitle {
    display: block;
    top: calc((100vh - 250px) / 2);
    left: 50%;
    width: auto;
    height: calc(100% - 300px);
    max-height: 350px;
    transform: translate(-50%, -50%);
  }
}

.videoWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
}

.indicator {
  position: absolute;
  left: 50%;
  bottom: 0px;
  z-index: 500;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%) scale(0.8);
}

@media only screen and (max-width: 990px) {
  .indicator {
    display: none;
  }
}

.mouse {
  width: 30px;
}

@media (min-aspect-ratio: 1920/1080) {
  video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: auto;
  }
}

canvas {
  display: block;
  z-index: 500;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  /* mix-blend-mode: multiply; */
}
@media only screen and (max-width: 990px) {
  canvas {
    display: none;
  }
}

/* .indicator * { */
/* border: solid 1px red; */
/* } */
.left,
.right {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.middleline {
  margin-top: 10px;
}

.flx * {
  display: inline-block;
  vertical-align: middle;
}

/* 泡泡選單 */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle__outblock {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .circle__outblock {
    // position: relative;
    position: absolute;
    width: calc(100% - 100px);
    margin-left: 50px;
  }
}
.circle {
  cursor: pointer;
  position: absolute;
  transition: 1.2s;
}
a.circle__block {
  text-decoration: none;
}
.circle:nth-child(1) .circle__block {
  animation: aniFloat 3s infinite ease-in-out;
}

.circle:nth-child(2) .circle__block {
  animation: aniFloat 2.5s infinite ease-in-out;
}

.circle:nth-child(3) .circle__block {
  animation: aniFloat 3.3s infinite ease-in-out;
}

.circle:nth-child(4) .circle__block {
  animation: aniFloat 2.9s infinite ease-in-out;
}

.circle:nth-child(5) .circle__block {
  animation: aniFloat 2.3s infinite ease-in-out;
}
@keyframes aniFloat {
  0%,
  100% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.circle:hover {
  transform: scale(1.2);
  transition: 0.7s;
}
.circle--artistroom {
  bottom: 20%;
  right: 38%;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .circle--artistroom {
    bottom: 90px;
    right: 78%;
  }
}
.circle--fmaf {
  bottom: 37%;
  right: 30%;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .circle--fmaf {
    bottom: 160px;
    right: 59%;
  }
}
.circle--fvl {
  bottom: 13%;
  right: 20%;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .circle--fvl {
    bottom: 30px;
    right: 35%;
  }
}
.circle--about {
  bottom: 25%;
  right: 10%;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .circle--about {
    bottom: 130px;
    right: 18%;
  }
}
.circle--activity {
  bottom: 36%;
  right: 3%;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .circle--activity {
    bottom: 200px;
    right: -3%;
  }
}
.circle__block {
  position: relative;
  align-self: center;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(32, 32, 32, 0.6) 0,
    #000 80%
  );
  width: 191px;
  height: 191px;
  border-radius: 50%;
  flex-direction: column;
}
@media screen and (max-width: 766px) and (min-width: 0px) {
  .circle__block {
    width: 143px;
    height: 143px;
  }
}
.circle__block--artistroom {
  width: 100vw;
  height: 100vw;
  max-width: 148px;
  max-height: 148px;
}
@media screen and (max-width: 766px) and (min-width: 0px) {
  .circle__block--artistroom {
    width: 21vw;
    height: 21vw;
  }
}
.circle__block--fmaf {
  width: 100vw;
  height: 100vw;
  max-width: 92px;
  max-height: 92px;
}
@media screen and (max-width: 766px) and (min-width: 0px) {
  .circle__block--fmaf {
    width: 20vw;
    height: 20vw;
  }
}
.circle__block--fvl {
  width: 100vw;
  height: 100vw;
  max-width: 142px;
  max-height: 142px;
}
@media screen and (max-width: 766px) and (min-width: 0px) {
  .circle__block--fvl {
    width: 28vw;
    height: 28vw;
  }
}
.circle__block--about {
  width: 100vw;
  height: 100vw;
  max-width: 121px;
  max-height: 121px;
}
@media screen and (max-width: 766px) and (min-width: 0px) {
  .circle__block--about {
    width: 20vw;
    height: 20vw;
  }
}
.circle__block--activity {
  width: 100vw;
  height: 100vw;
  max-width: 87px;
  max-height: 87px;
}
@media screen and (max-width: 766px) and (min-width: 0px) {
  .circle__block--activity {
    width: 19vw;
    height: 19vw;
  }
}
.circle__block__text {
  position: static;
  font-size: 18px;
  line-height: 29px;
  color: #fff;
}
@media screen and (max-width: 990px) and (min-width: 0px) {
  .circle__block__text {
    font-size: 14px;
  }
}

.navbar__lang {
  z-index: 100;
  position: fixed;
  top: 10px;
  right: 0;
  width: 100%;
  max-width: 105px;
  display: flex;
  align-items: center;
}

.navbar__lang__img {
  margin-right: 4px;
}

.navbar__lang__item {
  line-height: 12px;
  margin-right: 4px;
  padding-right: 8px;
  cursor: pointer;
  
  &:hover {
    background-color: #000000;
    color: white;
  }
  &.is__active {
    background-color: #000000;
    color: white;
  }
}
</style>
